<script setup lang="ts">
import type { Schemas } from "#shopware";

const props = withDefaults(
  defineProps<{
    products: Array<Schemas["Product"]>;
    title?: string;
  }>(),
  {
    title: "",
  },
);
const { products } = toRefs(props);
</script>

<template>
  <SharedHSlider v-if="products.length > 0" :title="props?.title || ''">
    <ProductCard
      v-for="product in products"
      :key="product.id"
      class="flex-shrink-0"
      :product="product"
    />
  </SharedHSlider>
</template>
